import { request } from "@/request/http";

/**
 * @name: 意见反馈接口
 * @author: LXY
 * @date: 2022-10-20
 */
export default {
  // 获取学校列表
  getSchoolList: (data) => {
    return request("POST", `/index.php/adminSchool/getSchoolList`, data);
  },
  // 获取虚拟实验列表
  getExpList: (data) => {
    return request("POST", `/index.php/adminExp/getExpList`, data);
  },
  // 获取意见反馈列表
  getSpitList: (data) => {
    return request("POST", `/index.php/adminSpit/getSpitList`, data);
  },
  // 删除反馈
  delSpitById: (data) => {
    return request("POST", `/index.php/adminSpit/delSpitById`, data);
  },
  // 快捷回复信息
  spitQuickReply: (data) => {
    return request("POST", `/index.php/adminSpit/spitQuickReply`, data);
  },
}